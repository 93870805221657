@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face { */
/* font-family: "Robotoo"; */
/* src: url("/src/fonts/roboto-regular.woff2") format("woff2"); */
/* font-display: swap; */
/* } */

body {
  font-family: "Roboto";
}

body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}
