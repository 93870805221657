/* styles.css */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border-top: 4px solid var(--border-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
