.loginScreen {
  background-color: #f3a738;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  background-color: white;
  border-radius: 15px;
  padding: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  animation: fadeIn 1s;
}

.inputField {
  border-radius: 10px;
  border: 1px solid rgb(240, 239, 239);
  padding: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  width: 300px;
  height: 35px;
  font-size: 17px;
  font-family: "Roboto";
  border-color: #dad8d8;
}

.inputField:focus {
  outline: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.button {
  border-radius: 15px;
  background-color: #f95026;
  border: 0;
  color: aliceblue;
  margin-bottom: 10px;
  width: 320px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  font-family: "Roboto";
}

.button:hover {
  outline: none;
  background-color: #b2391b;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.signIn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signIn-text {
  font-family: "Roboto";
  font-size: 17px;
}
.signIn-button {
  border: 0;
  background-color: transparent;
  font-family: "Roboto";
  font-size: 17px;
  color: #f95026;
  align-items: center;
}

.signIn-button:hover {
  outline: none;
  color: #b2391b;
}

.signIn-button-back {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s;
}

@keyframes slideIn {
  from {
    margin-left: 30px;
  }
  50% {
    margin-left: 200px;
  }
  to {
    margin-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  .loginBox {
    background-color: 100%;
    width: 100%;
    height: 85vh;
  }
}
